@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap'); 

:root {
	--white: #fff;
  --black: #000;
  --light-gray: #AFB3B6;
	--gray: #7E7E7E;
	--gray-dark: #4F4C58;
	--primary: #7166D6;
	--secondary: #858796;
	--success: #00A827;
	--info: #36b9cc;
	--warning: #FFBA33;
	--danger: #EA0000;
	--light: #f8f9fc;
	--dark: #1E1C24;
	--form-border-color: #FFE3C9; 
	--bg-light: #FFF9F5;
	--bg-color: #24252D;
	--table-border: #F5F5F5;
	--light-primary: #6F65D3;
	--border-color: #3B3A42;
	--purple: #9B2B8B;
	--darkblue: #2B449B;
}
* {
  padding: 0;
  margin: 0;
  box-shadow: none;
}
*,
::after,
::before {
	box-sizing: border-box
}
html {
	font-family: 'Comfortaa', sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent
}
body {
  position: relative;
  min-height: 100%;
  font-family: "Manrope", sans-serif !important;
  color: var(--white) !important;
  background-color: #191820 !important; 

}
h1, h2, h3, h4, h5, h6, p {
  color: var(--white);
}
a {
  text-decoration: none !important;
  color: var(--primary) !important;
  font-size: 0.875rem;
}
.pointer {
  cursor: pointer;
}
.gray {
  color: var(--gray);
}
.dark {
  color: var(--dark);
}
.primary {color: var(--primary);}
.white {
  color: var(--white) !important;
}
.green {color: var(--success);}
.form-control {
  color: var(--white) !important;
}
input.form-control {
  border: 1px solid var(--border-color);
  background: var(--dark) !important;
  height: 50px;
  border-radius: 15px;
}
.form-control:focus {
  background: none;
  color: var(--white);
  box-shadow: none !important;
}
.form-select:focus {
  box-shadow: none !important;
}
textarea.form-control {
  border: 1px solid var(--border-color);
  background: var(--dark) !important;
  height: 50px;
  border-radius: 15px;
}
.btn {border-radius: 0.625rem !important;}
.btn-primary {
  color: var(--dark) !important;
  background-color: var(--white) !important;
  border-color: var(--white) !important;
  padding: .75rem .75rem !important;
  /* min-width: 250px; */
}
.btn-primary:hover {
  border: 1px solid var(--light-gray) !important;
  background: var(--dark) !important;
  color: var(--white) !important;
}
a.btn.btn-primary:hover {
  color: var(--white) !important;
}
.btn-outline-primary {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.btn-outline-primary:hover {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
button.btn-outline-secondary {
  border: 1px solid var(--gray-dark) !important;
  color: #fff;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
  box-shadow: none !important;
}
.form-control:focus {
  border-color: var(--primary) !important;
}
ul.dropdown-menu {
  background: var(--bg-color);
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  color: var(--white);
  min-width: 220px;
  width: auto;
}
.danger {
  color: var(--danger) !important;
}
.dropdown-item {
  color: var(--white) !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: var(--white) !important;
  background-color: #3a3a3a !important;
}
.small, small {
  font-size: 0.8125em !important;
}
.btn-check:focus+.btn, .btn:focus {
  box-shadow: none !important;
}
.table>:not(caption)>*>* {
  background: var(--bg-color) !important;
}
tbody, td, tfoot, th, thead, tr {
  vertical-align: middle;
}
td {
  font-size: 14px;
}
.table>:not(:last-child)>:last-child>* {
  border-bottom-color: var(--primary) !important;
}
.border-top, .border-bottom, .border-left, .border-right {
  border-color: var(--border-color) !important;
}
.wrapper .border-bottom { border-bottom: 1px solid var(--border-color) !important;}
.wrapper .border-top { border-top: 1px solid var(--border-color) !important;}
.wrapper .border-right { border-right: 1px solid var(--border-color) !important;}
.wrapper .border-left { border-left: 1px solid var(--border-color) !important;}
.wrapper .border-primary {border-color: var(--light-primary) !important;}


.modal-content {
  background-color: var(--bg-color) !important;
  border-radius: 1rem !important;
  border: 1px solid var(--primary) !important;
}
.modal-dialog button.btn-close {
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  right: -13px;
  top: -13px;
  display: flex;
  background: var(--white);
  opacity: 1;
  color: var(--dark);
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.modal-header {
  border-bottom: 0 !important;
}
label.form-label {
  font-size: .875rem;
  color: var(--light-gray);
}
.modal-footer {
  border-top: 1px solid var(--border-color) !important;
}
select.form-select {
  border: 1px solid var(--border-color);
  background: var(--dark) !important;
  height: 50px;
  border-radius: 15px;
  color: var(--white);
}
span.select-icon {
  position: absolute;
  right: 15px;
  top: 13px;
}
.modal-footer {
  border-top: 0 !important;
}
.modal-title {
  margin-top: 20px;
}
.wrapper .border-primary {border-color: var(--light-primary) !important;}

.form-checked .form-check-input {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background-color: #25242c !important;
  border: 1px solid #fff !important;
}
label.form-check-label {
  margin-top: 3px;
  font-size: 0.875rem;
}
.cancel {
  color: var(--danger);
}
.warning {
  color: var(--warning);
}
.wrapper .bg-primary {
  background: var(--primary) !important;
}
.form-check.form-radio .form-check-input {
  background-color: #25242c;
  border: 1px solid #fff;
}
.mw-250 {min-width: 250px !important;}
.pagination {
  justify-content: end;
}
.page-link {
  padding: .375rem .75rem;
  margin: 0 6px;
  background-color: var(--gray-dark) !important;
  color: var(--white) !important;
  border: 1px solid var(--gray-dark) !important;
  border-radius: 5px;
}
.page-item.active .page-link {
  z-index: 3;
  color: var(--white);
  background-color: var(--primary) !important;
  border-color: var(--light-primary) !important;
}
.page-item:first-child .page-link, .page-item:last-child .page-link {
  border-radius: 5px !important;
}
/* basic-end */

/* signin-page-style */
.signin-page {
  background: url(./Assets/Images/signin-bg.png);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.sign-in-left-sec {
  background: url(./Assets/Images/signin-left.png);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-right-sec {
  color: var(--white);
  min-height: 580px;
  height: 100%;
}
.phone-form {
  display: flex;
  align-items: center;
  background: var(--dark);
  border: 1px solid var(--border-color);
  padding: 0 10px;
  border-radius: 15px;
  height: 50px;
  color: var(--white);
  width: 100%;
}
.phone-form .form-control {
  border: 0;
  background: none;
  color: var(--white) !important;
  height: 48px;
}
.signin-page .dropdown-toggle {
  color: var(--white) !important;
  border-right: 1px solid var(--white);
  border-radius: 0 !important;
}
.signin-page .form-control:focus {
  background: none;
  color: var(--white);
  box-shadow: none;
}
.phone-form span {
  color: #4F4C58;
}
.sign-in-right-sec label {
  color: var(--white);
}

.otp-input {
  display: flex;
  align-items: center;
}
.otp-input input {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  margin: 0 10px 0 0;
}
.sign-in-right-sec a {
  color: var(--white);
}
.sign-in-right-sec a:hover {
  color: var(--primary) !important ;
}

img.logo {
  width: 150px;
}

.input-group-text {
  border: 1px solid var(--border-color) !important;
  background-color: var(--dark) !important;
  border-right: 0 !important;
  border-radius: 50px 0 0 50px !important;
  color: var(--white) !important;
}

.web-card {
  background: var(--bg-color);
  padding: 30px;
  border-radius: 30px;
  position: relative;
}
.dash-service .card {
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 0;
}
.dash-service .card p {
  font-size: 0.875rem;
}
.dash-service {
  overflow: hidden;
}
.web-card.dash-service::before {
  content: "";
  width: 130px;
  height: 130px;
  background: url("./Assets/Images/banner-services/banner-service-icon-left.png") no-repeat ;
  top: 0;
  left: 0;
  position: absolute;
  display: inline-block;
  background-position: center;
  background-size: contain;
  }
  .web-card.dash-service::after {
  content: "";
  width: 130px;
  height: 130px;
  background: url("./Assets/Images/banner-services/banner-service-icon-right.png") no-repeat ;
  top: 0;
  right: 0;
  position: absolute;
  display: inline-block;
  background-position: center;
  background-size: contain;
  }
  ul.dropdown-menu.dropdown-menu-end.profile-drpdown {
    padding-top: 0;
}
.lst-prfl {
  margin-bottom: 10px;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
}
ul.profile-drpdown .dropdown-item  {
  font-size: .875rem;
  padding: .5rem 1rem;
}
ul.profile-drpdown .dropdown-item span {
  padding-left: 5px;
  width: 30px;
}
.danger-btn, .danger-btn p, .danger-btn:hover {
  color: var(--danger) !important;
}
ul.ntfcn-dropdwn h4 {
  font-size: 17px;
  margin-bottom: 0;
}
.unread-notfctn {
  width: 10px;
  height: 10px;
  display: block;
  background: var(--primary);
  border-radius: 50%;
}
ul.ntfcn-dropdwn .dropdown-item h5 {
  font-size: 1rem;
  margin-bottom: 5px;
}
ul.ntfcn-dropdwn .dropdown-item p {
  font-size: 13px;
  margin-bottom: 5px;
}
ul.ntfcn-dropdwn .dropdown-item small {
  font-size: 11px;
}
span.btn-ntfcn {
  width: 10px;
  height: 10px;
  display: block;
  background: #ff0000;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  top: 3px;
  right: 0;
}
ul.ntfcn-dropdwn li {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 10px;
  padding-bottom: 10px;
}
img.card-realtive {
  width: 100%;
  position: absolute;
  bottom: 0px;
  max-width: 430px;
  left: 50%;
  transform: translateX(-50%);
}
.web-card {
  min-height: 340px;
}
.navbar-brand.navbar-brand-mob {
  display: none;
}
.ntfctn-dtls {
  border-bottom: 1px solid var(--border-color);
  padding: 10px 5px;
  border-radius: 5px !important;
}
.ntfctn-dtls:hover {
  background: var(--gray-dark);
}
a.btn.opt-btn {
  background: var(--light-primary);
  margin-left: 5px;
  color: var(--white) !important;
}
.ntfctn-dtls h5 {
  font-size: 1rem;
}
.ntfctn-dtls p {
  font-size: 0.875rem;
  color: #ebe1e1;
}
small.ntfcn-date {
  color: var(--gray);
}
.ntfctn-dtls-text {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  color: #fff;
}
.web-card.wallet-card {
  background: var(--light-primary);
  min-height: 160px;
  border-radius: 20px !important;
  padding: 40px 30px;
  overflow: hidden;

}
.web-card.DB-card,
.web-card.relation-card{
  background: var(--black);
  min-height: 100px;
  border-radius: 20px !important;
  padding: 25px 140px 25px 30px;
  overflow: hidden;
}
.form-check-input:checked {
  background-color: var(--dark) !important;
  border-color: var(--white) !important;
}
span.debit {
  color: var(--danger);
}
span.credit {
  color: var(--success);
}

.contributors-sec {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.web-card.threshhold-card {
  min-height: auto;
}
.rchrg-sec h1 {
  color: var(--gray);
}
.rchrg-sec h1 span {
  color: var(--white);
}
.pay-card {
  position: relative;
  background-image: url(./Assets/Images/card-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 357px;
  height: 184px;
  display: block;
  border-radius: 20px;
  padding: 15px;
  width: 100%;
}
span.card-logo {
  position: absolute;
  right: 15px;
  top: 15px;
}
span.card-logo img {
  width: 50px;
}
.btn-group.card-option .dropdown-toggle {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background: var(--white);
  display: flex;
  color: var(--gray-dark);
}
.btn-group.card-option .dropdown-toggle:hover { color: var(--gray-dark); }
.btn-group.card-option .dropdown-toggle::after {
  display: none;
}
.btn-group.card-option ul.dropdown-menu {
  min-width: 170px;
  font-size: 0.875rem;
}

.dark-card {
  background: #1B1921;
  border-radius: 20px;
  padding: 20px !important;
}
img.card-banner-img {
  width: 100%;
  max-width: 350px;
}
ul.dropdown-menu {overflow: hidden;}

.categories-sec .card {
  overflow: hidden;
  border: 0;
  border-radius: 10px;
}
.categories-sec img {
  height: 170px !important;
  width: 100%;
  object-fit: cover;
}
.categories-sec p {
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
.categories-sec small.amt {
  min-height: 20px;
  display: block;
}
.rchrg-sec {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
.web-card.wallet-card::before {
  content: "";
  width: 130px;
  height: 130px;
  background: url("./Assets/Images/wallet-before.png") no-repeat;
  top: 0;
  right: 0;
  position: absolute;
  display: inline-block;
  background-position: center;
  background-size: contain;
}
.web-card.relation-card::before {
  content: "";
  width: 140px;
  height: 140px;
  background: url("./Assets/Images/relationship.png") no-repeat;
  top: -2px;
  right: 0;
  position: absolute;
  display: inline-block;
  background-position: center;
  background-size: contain;
}
.web-card.DB-card::before {
  content: "";
  width: 140px;
  height: 140px;
  background: url("./Assets/Images/DB-bg.png") no-repeat;
  top: -13px;
  right: 0;
  position: absolute;
  display: inline-block;
  background-position: center;
  background-size: contain;
}
.web-card.DB-card .bgDays {
  width: 115px;
}
.web-card.DB-card .bgDays-stn{
  position: absolute;
  display: flex;
  right: 65px; 
}
.web-card.DB-card .bgDays-text{
  position: absolute;
  transform: translate(20px,21px);
  font-size: 21px;
  cursor: pointer;
}
.highlight {
  background-color: #7166D6;
  padding: 13px 10px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 300;
}

.web-card.wallet-card.modal-wallet-card::before {
  width: 80px;
  height: 80px;
}
.web-card.wallet-card.modal-wallet-card {
  padding: 15px;
  min-height: auto;
}
a.btn-outline {
  border: 1px solid var(--gray-dark);
  color: var(--white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem .75rem !important;
}
a.btn-outline:hover, a.btn-outline.active {
  border: 1px solid var(--primary);
  color: var(--white) !important;
}
a.btn-outline-sm {
  border: 1px solid var(--gray-dark);
  color: var(--white) !important;
  padding: 2px 10px;
  min-width: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 30px;
  font-size: .875rem;
}
a.btn-outline-sm:hover, a.btn-outline-sm.active {
  border: 1px solid var(--primary);
  color: var(--white) !important;
  background: var(--primary);
}
.btn.back-btn {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
a.btn.back-btn {
  display: inline-flex;
  align-items: center;
  min-height: 40px;
}
a.btn.back-btn i {
  background: var(--white);
  width: 20px;
  height: 20px;
  border-radius: 7px;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 5px;
}
.wallet-rchrg-pg p {
  font-size: .875rem;
}
.profile-img img {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  object-fit: cover;
  margin: 10px;
}
.round {
  position: relative;
}
.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}
.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
.round input[type="checkbox"] {
  visibility: hidden;
}
.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}
.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
button.btn.dropdown-toggle.primary-dropdown {
  background: var(--primary);
  min-height: 40px;
}
button.btn.dropdown-toggle.primary-dropdown::after {
  display: none;
}
button.btn.dropdown-toggle.primary-dropdown span {
  background: #fff;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
span.track-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #BEBEBE;
  border-radius: 50%;
  border: 1px solid var(--white);
}
span.trackline {
  border-left: 2px dashed var(--white);
  height: 70px;
  display: block;
  margin-left: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
}
span.track-icon.active {
  background: var(--light-primary);
}
.phone-code-dropdown {
  border-right: 1px solid var(--white);
}
.phone-code-dropdown select {
  height: 40px !important;
  padding: 5px;
  min-width: 60px;
  text-align: center;
  cursor: pointer;
}
input::-webkit-input-placeholder { /* WebKit browsers */
  color: var(--gray-dark) !important;
}
input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: var(--gray-dark) !important;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: var(--gray-dark) !important;
}
input:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: var(--gray-dark) !important;
}
span.error {
  font-size: 0.75rem;
  color: var(--danger) !important;
}
.web-card.dash-service .card img {
  height: 150px;
  object-fit: cover;
  width: 100%;
}

img.pfl-list-img  {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
table.table-list th:last-child {
  text-align: end;
}
.non-editable {
  border: 1px solid var(--border-color);
  background: var(--dark) !important;
  height: 50px;
  border-radius: 15px;
  padding: .375rem .75rem;
  display: flex;
  align-items: center;
}

/* chat-sec */

.message-area {
  max-height: 100vh;
  overflow: visible;
}
section.message-area .modal-content {
  border: 0 !important;
}
.msg-body {
  margin-top: 30px;
}
.chat-area {
  position: relative;
  width: 100%;
  background-color: var(--bg-color);
  border-radius: 0.3rem;
  height: 90vh;
  overflow: visible;
  min-height: calc(100% - 1rem);
}
.chatlist {
  outline: 0;
  height: 100%;
  overflow: hidden;
  width: 300px;
  float: left;
  padding: 15px;
}
.chat-area .modal-content {
  border: none;
  border-radius: 0;
  outline: 0;
  height: 100%;
  overflow: visible !important;
}
.chat-area .modal-dialog-scrollable {
  height: 100% !important;
}
.chatbox {
  width: auto;
  overflow: visible;
  height: 100%;
}
.chatbox .modal-dialog,
.chatlist .modal-dialog {
  max-width: 100%;
  margin: 0;
}
.msg-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-area .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  background-color: #151516 !important;
  background-clip: padding-box;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.chat-area .form-control:focus {
  outline: 0;
  box-shadow: inherit;
}
a.add img {
  height: 36px;
}
.chat-list h3 {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}
.chat-list p {
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}
.chat-list a.d-flex {
  margin-bottom: 15px;
  position: relative;
  text-decoration: none;
}
.chat-list .active {
  display: block;
  content: '';
  clear: both;
  position: absolute;
  bottom: 3px;
  left: 34px;
  height: 12px;
  width: 12px;
  background: #00DB75;
  border-radius: 50%;
  border: 2px solid #fff;
}
.moreoption {
  display: flex;
  align-items: center;
  justify-content: end;
}
.moreoption .navbar {
  padding: 0;
}
.moreoption li .nav-link {
  color: #222;
  font-size: 16px;
}
.moreoption .dropdown-toggle::after {
  display: none;
}
.moreoption .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: auto;
  right: 0;
  margin-top: 0.125rem;
}
.msg-body ul {
  overflow: hidden;
  padding-left: 0;
}
.msg-body ul li {
  list-style: none;
  margin: 15px 0;
}
.msg-body ul li.sender {
  display: block;
  width: 100%;
  position: relative;
}
.msg-body ul li.sender p {
  color: #ffffff;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  padding: 8px 11px;
  background: var(--bg-color);
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 0;
}
.msg-body ul li.sender p b {
  display: block;
  color: #180660;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}
.msg-body ul li.reply {
  display: block;
  width: 100%;
  text-align: right;
  position: relative;
}
.msg-body ul li.reply p {
  color: var(--white);
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  padding: 8px 11px;
  background: var(--light-primary);
  display: inline-block;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 0;
}
li.reply a {
  color: var(--white) !important;
}
li.reply a.btn {
  font-size: 0.813rem !important;
}
.msg-body ul li.reply p b {
  display: block;
  color: #061061;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.divider {
  position: relative;
  z-index: 1;
  text-align: center;
}
.msg-body h6 {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: var(--dark);
  background: var(--white);
  display: inline-block;
  padding: 0 5px;
  margin-bottom: 0;
}
.send-box {
  padding: 15px;
  background: var(--black);
}
.send-box .form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatbox .modal-body {
  background: var(--black);
}
button.btn.sent-btn {
  background: var(--primary);
  color: var(--white) !important;
  height: 50px;
  display: flex;
  width: 50px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.send-box-inn {
  background: #0C0C0D;
  padding: 10px;
  border: 1px solid #252323;
  border-radius: 10px;
  position: relative;
}
/* chat-sec */

/* carousel-sec */
  .react-multi-carousel-item {margin-right: 10px;}
  .react-multiple-carousel__arrow {
    background: rgb(113 102 214) !important;
    min-width: 35px !important;
    min-height: 35px !important;
    border: 1px solid #68686882 !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.react-multiple-carousel__arrow:hover {
  background: rgba(113, 102, 214, 0.9) !important;
}
/* carousel-sec */

a.btn.back-btn.login-back {
  position: absolute;
  right: 25px;
}
a.btn.back-btn.login-back:hover {
  color: #fff !important;
}
ul.ntfcn-dropdwn {
  width: 350px;
}
ul.ntfcn-dropdwn .dropdown-item {
  word-wrap: normal !important;
}
td.numeric p {
  margin-bottom: 0;
}
.swal2-popup.swal2-modal.custom-popup {
  background: #191820;
  border: 1px solid #7166D6;
  border-radius: 25px;
  padding: 40px;
}
.swal2-html-container h3 {
  line-height: 40px !important;
  /* text-transform: capitalize; */
  color: var(--white);
  margin-top: 20px;
}
h2.swal2-title.custom-title {
  color: #fff;
}
.custom-confirm-button {
  min-width: 100px;
}
.topup-amt {
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.topup-amt a.btn.btn-outline-sm {
  min-width: auto !important;
}
.swal2-popup.swal2-modal.swal2-icon-warning {
  background: #191820;
  border: 1px solid #7166D6;
  border-radius: 25px;
  padding: 40px;
  color: #fff;
}
.pay-card-list {
  width: 350px;
  margin-right: 20px;
}

/* scroll-bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(78, 78, 78); 
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light-primary); 
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary); 
}
/* scroll-bar */

.border-right-responsive {
  border-right: 1px solid var(--border-color);
}
button.swal2-confirm.btn.btn-primary {
  min-width: 100px;
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--white) !important;
}
button.swal2-confirm.btn.btn-primary:hover {
  background-color: var(--light-primary) !important;
  border: 1px solid var(--light-primary) !important;
}
button.swal2-cancel {
  min-width: 100px;
  height: 50px;
  border-radius: 0.625rem !important;
}
.ReactCrop__child-wrapper {max-height: 400px !important;}

.categories-sec .carousel-item {
  display: flex;
  background: #191820;
  border-radius: 10px;
  overflow: hidden;
  padding: 0 10px 10px;
  border: 1px solid var(--white);
  height: 100%;
  align-items: center;
}
.categories-sec .carousel-item p {
  white-space: unset;
  text-overflow: unset;
  width: auto;
  overflow: unset;
  word-break: break-word;
}
.categories-sec .carousel-item img {
  width: 100%;
  height: auto;
  /* display: block; */
  display: none;
}
li.react-multi-carousel-item {
  max-width: 250px;
}
.nav-notfctn {
  width: 95%;
  white-space: break-spaces;
}
img.service-stats-img {
  max-width: 250px;
  margin: 0 auto;
  display: block;
  width: 100%;
}
.max-500 {
  max-height: 500px;
  overflow: auto;
}
.dark-card .pay-card { margin: 0 auto;}
.breadcrumb-item+.breadcrumb-item::before {color: var(--light-gray) !important;}
.breadcrumb-item.active {color: var(--white) !important;}
li.breadcrumb-item a {cursor: pointer;}
.membrshp-bill {
  background: #3B3A42;
  padding: 20px 10px 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.membrshp-bill .rdio label:before {
  right: 0 !important;
  top: -10px;
  left: auto !important;
}
.membrshp-bill .rdio input[type=radio]:checked + label::after {
  right: 4px !important;
  top: -6px;
  left: auto !important;
}
 /* radio-button */
 .rdio {
  position: relative;
}
.rdio input[type=radio] {
  opacity: 0;
}
.rdio label {
  padding-left: 10px;
  cursor: pointer;
  margin-bottom: 7px !important;
}
.rdio label:before {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 0;
  content: "";
  display: inline-block;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border: 1px solid var(--primary);
  background: var(--bg-color);
}
.rdio input[type=radio] {
  margin: 0px;
}
.rdio input[type=radio]:disabled + label {
  color: #999;
}
.rdio input[type=radio]:disabled + label:before {
  background-color: var(--light-primary);
}
.rdio input[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  display: inline-block;
  font-size: 11px;
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
 /* radio-button */
 ::marker { 
  color: var(--primary);
}
.modal-body {
  padding: 25px !important;
}
ul {
  padding-left: 15px !important;
  margin-bottom: 10px;
}
ul li {margin-bottom: 5px;}
p.renewal-tag {
  background: #ee9504;
  display: inline-block;
  margin-bottom: 0;
  padding: 2px 14px;
  font-size: 13px;
  border-radius: 15px;
  margin-top: 8px;
}
ul.dropdown-menu.dropdown-menu-end {
  padding-right: 15px;
}
.form-control.input-form-control {
  background: var(--dark);
  border-radius: 15px;
  border: 1px solid var(--border-color);
}
.form-control.input-form-control .MuiInputBase-formControl {
  border: 0;
  border-radius: 15px;
}
.form-control.input-form-control input {
  background: var(--dark);
  border-radius: 15px;
  color: var(--white);
}
.form-control.input-form-control svg {
  fill: var(--gray) !important;
}
.form-control.input-form-control {
  background: var(--dark);
  border-radius: 15px;
  border: 1px solid var(--border-color);
  border-bottom-left-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
.week-buttons {
  display: flex;
  flex-wrap: wrap;
}
.week-buttons button {
  width: 95px;
  height: 45px;
  border-radius: 10px;
  background: var(--dark);
  color: var(--white);
  box-shadow: none;
  border: 1px solid var(--border-color);
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.week-buttons button.active {
  background: var(--primary);
  border: 1px solid var(--primary); 
}
.week-select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.week-select-box select {
  background: var(--dark);
  height: 40px;
  width: 120px;
  border-radius: 15px;
  color: var(--white);
  padding: 0 10px;
  border: 1px solid var(--border-color);
}
.week-select-box h5 {
  margin-bottom: 0;
  font-size: 16px;
}
.membrshp-bill label {
  background: #343434;
  padding: 5px 20px;
  border-radius: 20px;
}
img.membrship-logo {
  max-width: 130px;
}
.select-card-sec label:before {width: 22px; height: 22px;}
.select-card-sec input[type=radio]:checked + label::after {top: 7px; left: 6px;}
.chat-bubble-sec {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
a.chat-bubble {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #7166d6;
  color: #fff !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: all ease 6s;
}
.chat-bubble-sec span.support-txt {
  right: -10px;
  top: -32px;
  color: #fff;
  position: absolute;
  font-size: 14px;
  background: #333;
  padding: 5px 16px;
  border-radius: 20px;
  display: none;
  
}
.chat-bubble-sec:hover span.support-txt {
  display: block;
  top: -38px;
  transition: all ease 6s;
}
span.chat-time {
  display: block;
  margin-top: 3px;
  font-size: 12px;
  color: gray;
}
.chat-upload {
  width: 150px;
  height: 150px;
  border-radius: 10px !important;
  transition: all ease 6s;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #333;
  margin-left: 10px;
  margin-bottom: 10px;
}
.chat-upload-img {
  width: 100%;
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
}
a.rmv-btn {
  position: absolute;
  z-index: 99;
  top: 5px;
  right: 5px;
  font-size: 13px;
  color: var(--white) !important;
  background: var(--danger);
  width: 27px;
  height: 27px;
  border-radius: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all ease 6s;
}
.chat-upload:hover a.rmv-btn{
  display: flex;
  transition: all ease 6s;
}
.chat-loader {
  width: 150px;
  height: 150px;
  background: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-left: 10px;
}
.chat-loader img {
  width: 70px;
}
a.btn.chat-btn {
  padding: 1px 7px;
  font-size: 14px;
  color: #fff !important;
  background: #6f65d3;
  margin-top: 5px;
}
a.icon-btn i {
  background: unset !important;
  color: #fff !important;
  font-size: 16px !important;
}
a.btn.back-btn img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
span.count {
  background: var(--primary);
  width: 27px;
  display: inline-flex;
  height: 27px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-left: 5px;
  font-size: 0.875rem !important;
}
.fs-7 {font-size: 0.875rem;}
p.membership-notfctn {
  margin-bottom: 0;
  /* background: #FFF384; */
  /* color: #877900; */
  font-size: 0.875rem;
  font-weight: 600;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: 5px;
}
p.billed-tag {
  background: #6058B2;
  display: inline-block;
  margin-bottom: 7px;
  padding: 7px 15px;
  font-size: 0.875rem;
  border-radius: 20px;
}
.card-info {
  padding: 10px;
  border-radius: 10px;
}
.selected-card {
  border: 1px solid var(--light-primary);
  background: #000;
}
.react-multiple-carousel__arrow--right {
  right: calc(0.5% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0.5% + 1px) !important;
}
.react-multi-carousel-track {
  margin-left: -15px !important;
}
.autocomplete-dropdown-container {
  border-radius: 10px !important;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 99;
  left: 0;
  padding: 0;
  top: 70px;
}
.suggestion-item {
  background: #1a1a1a !important;
  color: #fff;
  border: 0 !important;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 13px;
  padding: 5px 15px;
}
.suggestion-item-active {
  background: #2d2d2d !important;
  color: #fff;
  border: 0 !important;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 13px;
  padding: 5px 15px;
}
.autocomplete-dropdown-container.chat-auto-drop {
  top: auto;
  bottom: 70px;
}
button.btn.btn-outline-priary {
  border: 1px solid var(--white);
  color: var(--white);
}
button.btn.btn-outline-priary:hover {
  background: var(--white);
  color: var(--dark);
}
.btn-outline-danger {
  color: #d47374 !important;
}
.btn-outline-danger:hover{ color: var(--white);}
.btn-outline-danger:hover img {filter: brightness(0) invert(1);}
a.chat-bubble span.btn-ntfcn {
  top: unset;
  bottom: 35px !important;
  width: 12px;
  height: 12px;
}
.btn.btn-outline-danger:hover {
  color: var(--white) !important;
}
.login-grp-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
button.btn.btn-primary-outline {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--bg-color);
  color: var(--white);
  background: #191919;
}
button.btn.btn-primary-outline.active {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}
img.scan-code {
  width: 150px;
}
.dwnload-app {
  padding: 10px 20px;
  margin: 5px;
  background: var(--primary);
  min-width: 220px;
  min-height: 60px;
  display: flex;
  align-items: center;
  font-size: 20px;
  border-radius: 10px;
  color: var(--white) !important;
}
.dwnload-app.app-store {background: #FF8D84;}
.dwnload-app i {font-size: 30px;}
.dwnload-app.app-store i {font-size: 35px;}
.dwnload-app h4 {
  margin-bottom: 0;
  font-size: 20px;
}
.dwnload-app p {
  font-size: 13px;
  margin-bottom: 0;
}
.bank-stripe {
  font-size: 1.50rem;
  padding-top: 5px;
}
.card-logo.bank-card-logo img {
  width: 35px;
}
.relation-sty {
  position: sticky;
  top: 0;
  z-index: 1;
}
.highlightImg{width: 200px;}
a.order-to {
  display: inline-flex;
  align-items: center;
  padding: 5px 0;
}
a.order-to p {margin-bottom: 0;}
a.order-to {color: var(--light-gray);}
.myself-sec {
  display: flex;
  align-items: center;
}
img.prfl-sec {
  width: 35px;
  height: 35px;
  background: var(--white);
  margin-right: 10px;
  border-radius: 50%;
}
span.span-tag {
  background: var(--primary);
  color: var(--white);
  padding: 0 10px;
  height: 22px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  margin-left: 10px;
}
.select-user .membrshp-bill {
  text-align: start;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  padding-left: 15px !important;
  padding-top: 15px;
}
.select-user .membrshp-bill img.prfl-sec {
  width: 33px;
  height: 33px;
  margin: 0;
}
.membrshp-bill .rlshp-img {
  border: 1px solid var(--border-color);
  padding: 2px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}
.membrshp-bill.select-rlshp .rlshp-img {
  border: 1px solid var(--primary);
}
.phone-code-dropdown select.form-select {
  border: 0;
}
.enable-payment {
  background: #000;
  padding: 15px;
  border-radius: 10px;
}
.sub-hd-relashp {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
}
.sub-hd-relashp img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.sub-hd-relashp h5 {
  margin-bottom: 0;
  font-size: 18px;
}
.rlshp-settings {
  position: absolute;
  top: 0;
  right: 0;
}
.rlshp-settings .dropdown-toggle::after {
  display: none;
}
.view-rltionshp .profile-img img {
  width: 90px;
  height: 90px;
}
p.verified span {
  color: var(--primary);
}
.rltnshp-body .web-card.wallet-card {
  padding: 20px;
  min-height: auto;
}
a.order-to i {
  color: var(--light-gray);
}
.rdio.rdio-primary.radio-inline.selection label::before {
  top: 27px;
  left: 10px;
}
.rdio.rdio-primary.radio-inline.selection input[type=radio]:checked + label::after {
  top: 31px;
  left: 14px;
}
.select-user .membrshp-bill label {
  padding: 0;
  margin: 0;
}
a.order-to p {
  font-size: 1rem;
}
.rlshp-img span {
  position: absolute;
  left: 11px;
  bottom: -6px;
  background: var(--primary);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  padding-top: 3px;
  padding-left: 1px;
}
.membrshp-bill.select-rlshp .rlshp-img  span {display: flex;}
.membrshp-bill.wallet-selection label {
  background: transparent;
  padding: 0;
  padding-left: 15px;
  font-size: 18px;
  margin-bottom: 0 !important;
}
.membrshp-bill.wallet-selection label::before {
  top: 23px !important;
}
.membrshp-bill.wallet-selection input[type=radio]:checked + label::after {top: 27px !important;}
.membrshp-bill.wallet-selection {padding-top: 10px; background: var(--black);}
/* -------------------------------------------------------- responsive-sec -------------------------------------------------------- */

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) { 
  
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
  .phone-form{max-width: 100%;}
  img.card-wallet {max-width: 150px; margin-bottom: 20px;}
  img.card-realtive {max-width: 330px;}
  .border-right-responsive {border-right: 0;}
  li.react-multi-carousel-item {max-width: 205px;}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
  .sign-in-left-sec img {width: 200px;}
  .btn-primary {font-size: 0.875rem;}
  li.react-multi-carousel-item {max-width: 195px;}
  img.scan-code {width: 130px !important;}
  .rltnshp-txt {width: 100%;}
}
 
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  .signin-page{height: 100%;}
  .sign-in-left-sec img {width: 150px;}
  .sign-in-left-sec {height: 100%; min-height: 360px;}
  .right-sec {gap: 15px !important;}
  .pftl-txt-hd {display: none;}
  .dash-service::before, .dash-service::after {width: 90px;height: 90px;}
  .wallet-rchrg-pg .border-right {border-right: 0 !important;}
  .web-card.dash-service::before, .web-card.dash-service::after {display: none;}
  img.card-realtive {max-width: 300px;}
  img.card-wallet {max-width: 100px;}
  .dark-card p {padding: 0 10px; font-size: 14px;}
  .sign-in-right-sec{min-height: 420px;}
  .web-card.DB-card .bgDays-stn {right: 22px;}
}
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  #content-wrapper {padding: 10px 0 !important;}
  .web-card {padding: 15px;}
  .modal-content {margin: 0 15px;}
  .pay-card {width: 280px;height: 161px;}
  .dark-card {padding: 20px 0 !important;}
  .dash-service::before, .dash-service::after {display: none;}
  .modal-dialog button.btn-close{right: 5px; top: 5px;}
  .pay-card-list {width: auto;}
  a.chat-bubble{right: 0; bottom: 0;}
  button.btn.btn-primary-outline {font-size: 14px;}
  button.btn.btn-primary-outline span {font-size: 20px;}
  .sign-in-left-sec h6 {font-size: 0.875rem;}
  .dwnload-app{padding: 10px; min-height: 40px; min-width: 160px;}
  .dwnload-app h4 {font-size: 17px;}
  .dwnload-app i {font-size: 23px;}
  .dwnload-app.app-store i {font-size: 28px;}
  .view-rltionshp .profile-img img {width: 70px; height: 70px; margin: 5px;}
 }








