@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap'); 
a {
    transition: background 0.2s, color 0.2s;
  }
  a:hover,
  a:focus {
    text-decoration: none;
  }
  
  .wrapper {
    padding-left: 0;
    transition: all 0.5s ease;
    position: relative;
  }
  
  .sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: url(../../Assets/Images/sidebar-bg.png);
    transition: all 0.5s ease;
  }
  
  .wrapper.toggled .sidebar-wrapper {
    width: 250px;
  }
  .sidebar-brand a {
    display: flex;
    justify-content: center;
}
  .sidebar-brand {
    /* position: absolute;
    top: 0; */
    width: auto;
    text-align: left;
    padding: 40px 0;
    /* margin-left: 30px; */
  }
  .sidebar-brand h2 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
  }
  
  .sidebar-nav {
    /* position: absolute;
    top: 75px; */
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 0 !important;
  }
  .sidebar-nav > li {
    /* text-indent: 10px; */
    line-height: 42px;
  }
  .sidebar-nav li a {
    display: flex;
    text-decoration: none;
    color: #757575;
    font-weight: 600;
    font-size: 0.938rem;
    align-items: center;
    background: transparent;
    margin: 5px 20px;
    padding-right: 15px;
    border-radius: 10px;
    height: 45px;
}
  .sidebar-nav li a:hover,
  .sidebar-nav li.active a {
    text-decoration: none;
    color: #fff;
    background: #7166D6;
  }
  .sidebar-nav li.active a {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  .sidebar-nav > li a p {
    margin-bottom: 0;
  }
  .sidebar-nav  li  a span.navicon {
    font-size: 22px;
    width: 40px;
    text-align: center;
    color: #fff !important;
  }
  
  #navbar-wrapper {
      width: 100%;
      position: absolute;
      z-index: 2;
  }
  .wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -250px;
  }
  #navbar-wrapper .navbar {
    border-width: 0 0 0 0;
    background-color: #191820;
    font-size: 24px;
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid #202020;
    padding-top: .75rem;
    padding-bottom: .75rem;
}
  #navbar-wrapper .navbar a {
    color: #757575;
  }
  #navbar-wrapper .navbar a:hover {
    color: #7166D6;
  }
  
  #content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
    top: 100px;
  }
  .wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }
  .navbar-list {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: calc(100vh - 150px);
}
a.btn.wallet-btn {
  background: #24252D;
  border-radius: 40px;
  position: relative;
  min-width: 100px;
}
a.btn.wallet-btn span {
  padding-left: 25px;
  color: #fff;
}
a.btn.wallet-btn img {
  position: absolute;
  left: -5px;
  top: -2px;
  width: 40px;
}
.right-sec {
  display: flex;
  align-items: center;
  gap: 25px;
}
img.pfl-img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.pftl-txt {
  text-align: left;
}
.pftl-txt span {
  font-size: 13px;
  display: block;
  color: #AFB3B6;
}
.pftl-txt p {
  margin-bottom: 0;
}
.right-sec .dropdown-toggle::after {
  display: none;
}
.right-sec .dropdown-toggle {
  white-space: nowrap;
  padding: 0;
  background: transparent;
  border: 0;
}
.navbar-header {
  width: 100%;
  padding: 0 15px;
}
.input-group.search-box {
  width: 100% !important;
  max-width: 350px;
  position: relative;
}
span.unread-notfctn {
  width: 10px !important;
  height: 10px;
  display: block;
  background: #7166d6;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
}
.wrapper.toggled .sidebar-nav li a {
  width: 45px;
  padding-right: 0;
  margin: 5px 8px;
  text-align: center;
  justify-content: center;
}
.wrapper.toggled .navbar-list p {
  display: none;
}
img.logo-res {
  width: 40px;
  height: 49px;
  display: none;
}
.wrapper.toggled img.logo {
  display: none;
}
.wrapper.toggled img.logo-res {
  display: block;
}
/* .wrapper.toggled .sidebar-brand {
  margin-left: 10px;
} */
.sidebar-nav li img {
  width: 23px;
  height: 23px;
  margin: 0 10px;
}
a.sidebar-toggle.res-menu {
  display: none;
  background: #25242c;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 10px;
  border: 1px solid #333;
}
.input-group.search-box input.form-control {
  border-radius: 0 50px 50px 0 !important;
  border-left: 0 !important;
  color: var(--white) !important;
  height: 45px !important;
  font-size: 0.875rem;
}
.input-group.search-box input.form-control:focus {
  border-color: #3B3A42 !important;
}
button.input-clear-button {
  border: 0;
  width: 20px !important;
  height: 20px !important;
  line-height: normal;
  background: #646464;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  color: #020202;
  padding: 0;
  font-size: 21px;
  margin-top: 12px;
  margin-left: -30px !important;
  z-index: 9;
}
.input-group.search-box ul.list-group {
  width: 100%;
  font-size: 12px;
  border-radius: 4px !important;
  border: 1px solid #313131;
  position: absolute;
  top: 47px;
}
.input-group.search-box ul.list-group li.list-group-item {
  background: #1a1a1a !important;
  color: #fff;
  border: 0 !important;
  cursor: pointer;
  margin-bottom: 0;
}
p.no-notf-records {
  text-align: center;
  font-size: 12px;
  margin: 20px;
}
.sidebar-nav li a:hover p span.count, .sidebar-nav li.active a p span.count {
  background: #fff;
  color: var(--primary);
  font-size: 0.875rem !important; }
/* nav-responsive */

  @media (min-width: 992px) {
    .wrapper {
      padding-left: 250px;
    }
    
    .wrapper.toggled {
      padding-left: 60px;
    }
  
    .sidebar-wrapper {
      width: 250px;
    }
    
    .wrapper.toggled .sidebar-wrapper {
      width: 60px;
    }
    
    .wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -190px;
  }
    
    .wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -190px;
    }
  
    #navbar-wrapper {
      position: relative;
    }
  
    .wrapper.toggled {
      padding-left: 60px;
    }
  
    #content-wrapper {
      position: relative;
      top: 0;
    }
  
    .wrapper.toggled #navbar-wrapper,
    .wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 60px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .wrapper {
      padding-left: 60px;
    }
  
    .sidebar-wrapper {
      width: 60px;
    }
    
  .wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -250px;
  }
    
    .wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -250px;
    }
  
    #navbar-wrapper {
      position: relative;
    }
  
    .wrapper.toggled {
      padding-left: 250px;
    }
  
    #content-wrapper {
      position: relative;
      top: 0;
    }
  
    .wrapper.toggled #navbar-wrapper,
    .wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 250px;
    }
  }
  @media (max-width:991px) {
    .wrapper.toggled .sidebar-nav li a {
      width: auto !important;
      padding-right: 0 !important;
      margin: 5px 8px !important;
      text-align: center !important;
      justify-content: flex-start !important;
    }
    .wrapper .sidebar-nav li a {
      width: 45px;
      padding-right: 0;
      margin: 5px 8px;
      text-align: center;
      justify-content: center;
  }
    .wrapper.toggled .navbar-list p {
      display: block;
    }
    .wrapper .navbar-list p {
      display: none;
    }
    img.logo-res {
      width: 40px;
      display: none;
    }
    .wrapper img.logo {
      display: none;
    }
    .wrapper img.logo-res {
      display: block;
    }
    .wrapper .sidebar-brand {
      margin-left: 10px;
    }
    .wrapper.toggled img.logo {
      display: block;
    }
    .wrapper.toggled img.logo-res {
      display: none;
  }
    
  }
  @media (max-width: 767px) {
    .wrapper {
      padding-left: 0;
    }
  
    .sidebar-wrapper {
      width: 0;
    }
    .wrapper.toggled .sidebar-wrapper {
      width: 250px;
      border-right: 1px solid #333;
  }
    .wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -250px;
    }
  
    .wrapper.toggled #content-wrapper {
      position: absolute;
      margin-right: -250px;
    }
  
    #navbar-wrapper {
      position: relative;
    }
    #content-wrapper {
      position: relative;
      top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  
    .wrapper.toggled #navbar-wrapper,
    .wrapper.toggled #content-wrapper {
      position: relative;
      margin-right: 250px;
    }
    
    a.sidebar-toggle.res-menu {
      display: flex;
  }
  .navbar-header {
    padding: 0;
  }
    
  }
  @media (max-width: 575px) {
    a.sidebar-toggle.res-menu {position: absolute; left: 15px; top: 17px;}
    .input-group.search-box input.form-control {height: 45px !important; max-width: 100%; padding-left: 5px;}
    ul.ntfcn-dropdwn.show {right: -66px;}
    .input-group.search-box {max-width: 100%;}
  }
  